<template>
  <div class="dashboard-page">
    <MetaManager
        title="Business Dashboard"
        description="See all the data your need to see what your business is doing on our platform"
      />
    <div class="dashboard-content">
      <div class="top-bar">
        <h1>Dashboard</h1>
        <div class="cards-container">
          <RevenueCard :salesAmount="salesAmount" />
          <CustomerCard :payouts="payouts" />
          <OrderCard :totalOrders="totalOrders" />
        </div>
        <EarningsReport :totalSales="totalSales" :chart-data="chartData" />
        <div class="orders-summary-container">
          <!-- Commented out ShowOrders Component -->
          <!-- <ShowOrders :orders="orders" /> -->

          <!-- Commented out GiftCardSalesSummary Component -->
          <!-- <GiftCardSalesSummary :orders="orders" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/Api';
import RevenueCard from '../../components/Dashboard/home/TotalSales.vue';
import CustomerCard from '../../components/Dashboard/home/TotalPayout.vue';
import OrderCard from '../../components/Dashboard/home/TotalOrders.vue';
import EarningsReport from '../../components/Dashboard/home/EarningsReport.vue';
// import ShowOrders from '../../components/Dashboard/home/ShowOrders.vue';
// import GiftCardSalesSummary from '../../components/Dashboard/home/GiftCardSalesSummary.vue';
import MetaManager from '../../components/MetaManager.vue';

export default {
  components: {
    RevenueCard,
    CustomerCard,
    OrderCard,
    EarningsReport,
    // ShowOrders,
    // GiftCardSalesSummary,
    MetaManager,
  },
  data() {
    return {
      salesAmount: 0,
      monthlySales: [],
      totalSales: 0,
      payouts: 0,
      totalOrders: 0,
      orders: [],
    };
  },
  computed: {
    chartData() {
      return {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Earnings',
            data: this.monthlySales, // Assign monthlySales to the data property
            backgroundColor: 'rgba(54, 162, 235, 0.7)',
            borderColor: 'rgba(54, 162, 235, 0.7)',
            borderWidth: 1,
            borderRadius: 5,
          }
        ]
      };
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        await this.fetchSalesAmount();
        await this.fetchOrders();
      } catch (error) {
        console.error("There was an error fetching data:", error);
      }
    },
    async fetchSalesAmount(retryCount = 0) {
      try {
        const currentDate = new Date();
        const monthIndex = currentDate.getMonth();
        const businessId = JSON.parse(localStorage.getItem('user')).business_id;
        const response = await ApiService.getSalesAmount(businessId);

        this.salesAmount = parseFloat(response.data[0].sales_amount[monthIndex]).toFixed(2);
        this.monthlySales = response.data[0].sales_amount;
        this.totalSales = this.monthlySales.reduce((total, amount) => total + amount, 0).toFixed(2);
        this.payouts = response.data[0].payouts[monthIndex];
        this.totalOrders = response.data[0].orders_amount[monthIndex];

        this.formatLargeNumbers();
      } catch (error) {
        if (retryCount < 3) {
          console.warn(`Retrying fetchSalesAmount (${retryCount + 1}/3)`);
          await this.sleep(1000);
          await this.fetchSalesAmount(retryCount + 1);
        } else {
          console.error("There was an error fetching sales amount:", error.response);
          throw error;
        }
      }
    },
    async fetchOrders(retryCount = 0) {
      try {
        const businessId = JSON.parse(localStorage.getItem('user')).business_id;
        const response = await ApiService.getOrders(businessId);
        this.orders = response.data;
      } catch (error) {
        if (retryCount < 3) {
          console.warn(`Retrying fetchOrders (${retryCount + 1}/3)`);
          await this.sleep(1000);
          await this.fetchOrders(retryCount + 1);
        } else {
          console.error("There was an error fetching orders:", error.response);
          throw error;
        }
      }
    },
    formatLargeNumbers() {
      if (this.salesAmount >= 1000) {
        this.salesAmount = this.salesAmount.toLocaleString();
      }
      if (this.totalSales >= 1000) {
        this.totalSales = this.totalSales.toLocaleString();
      }
      if (this.payouts >= 1000) {
        this.payouts = this.payouts.toLocaleString();
      }
      if (this.totalOrders >= 1000) {
        this.totalOrders = this.totalOrders.toLocaleString();
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
};
</script>

<style scoped>
.dashboard-content {
  margin-left: 300px; /* Adjust this value to match the width of your sidebar */
  flex-grow: 1; /* Takes up the remaining space */
  padding: 20px;
  overflow-y: auto; /* To ensure the content scrolls if it's too long */
  height: 100vh; /* To take full height of the viewport */
  box-sizing: border-box; /* To include padding in the element's total width and height */
  margin-bottom: 50px;
}

.dashboard-content h1 {
  margin-top: 0; /* Reset margin */
}

.cards-container {
  display: flex;
  gap: 30px; /* Adjust this value to add space between the cards */
}

.orders-summary-container {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 10px; /* Adjust sidebar width for medium screens */
    margin-top: 80px;
    padding: 15px; /* Adjust padding for medium screens */
  }

  .cards-container {
    gap: 20px; /* Adjust gap between cards for medium screens */
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 200px; /* Adjust sidebar width for small screens */
    padding: 10px; /* Adjust padding for small screens */
    margin-top: 75px;
    text-align: center;
  }

  .cards-container {
    gap: 15px; /* Adjust gap between cards for small screens */
  }
}

@media (max-width: 480px) {
  .dashboard-content {
    margin-left: 0; /* Remove sidebar margin for very small screens */
    padding: 5px; /* Adjust padding for very small screens */
    padding-top: 50px;
  }

  .cards-container {
    gap: 10px; /* Adjust gap between cards for very small screens */
    flex-direction: column; /* Stack cards vertically on very small screens */
  }
}
</style>
