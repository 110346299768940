<template>
  <div class="dashboard-content">
    <MetaManager
        title="Admin - Update User"
        description="Update users on the site"
      />
    <div class="form-container">
      <h1>Update User</h1>
      <div class="form-group">
        <input
          type="text"
          v-model="searchTerm"
          :placeholder="`Search user by name, email or phone number`"
          @input="searchUser"
          class="input-field"
        />
        <div v-if="userSuggestions.length > 0" class="suggestions">
          <ul>
            <li
              v-for="user in userSuggestions"
              :key="user.id"
              @click="selectUser(user)"
            >
              {{ user.email }} - {{ user.full_name }} {{ user.phone ? `(${user.phone})` : '' }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="user">
        <form @submit.prevent="updateUser">
          <div class="form-group-inline">
            <label>ID:</label>
            <span class="form-value">{{ user.id }}</span>
          </div>
          <div class="form-group">
            <label>Full Name:</label>
            <input type="text" v-model="user.full_name" class="input-field" />
          </div>
          <div class="form-group">
            <label>Email:</label>
            <input type="text" v-model="user.email" class="input-field" />
          </div>
          <div class="form-group-inline">
            <label>Is Verified:</label>
            <input type="checkbox" v-model="user.is_verified" class="checkbox-field" />
          </div>
          <div class="form-group-inline">
            <label>Is Admin:</label>
            <input type="checkbox" v-model="user.is_admin" class="checkbox-field" />
          </div>
          <div class="form-group-inline">
            <label>Is Business:</label>
            <input
              type="checkbox"
              v-model="user.is_business"
              @change="checkBusinessStatus"
              class="checkbox-field"
            />
          </div>
          <div class="form-group" v-if="user.is_business">
            <label>Business ID:</label>
            <input type="text" v-model="user.business_id" readonly class="input-field" />
          </div>
          <div class="form-group" v-if="user.is_business">
            <input
              type="text"
              v-model="businessName"
              placeholder="Search for business"
              @input="searchBusiness"
              class="input-field"
            />
            <div v-if="businessSuggestions.length > 0" class="suggestions">
              <ul>
                <li
                  v-for="business in businessSuggestions"
                  :key="business.id"
                  @click="selectBusiness(business)"
                >
                  {{ business.business_name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-buttons">
            <button type="submit" class="submit-button">Update User</button>
            <button type="button" @click="banUser" class="ban-button">Ban User</button>
            <button type="button" @click="cancelUpdate" class="cancel-button">Cancel</button>
          </div>
        </form>
        
        <!-- Password Reset Link Section -->
        <div class="password-reset-section">
          <button type="button" @click="generateResetLink" class="reset-password-button" :disabled="isGeneratingLink">
            {{ isGeneratingLink ? 'Generating...' : 'Generate Password Reset Link' }}
          </button>
          <div v-if="resetLink" class="reset-link-container">
            <p>Password Reset Link:</p>
            <div class="reset-link-display">
              <input type="text" readonly :value="resetLink" class="reset-link-input" ref="resetLinkInput" />
              <button @click="copyResetLink" class="copy-button">Copy</button>
            </div>
            <p v-if="linkCopied" class="copy-confirmation">Link copied to clipboard!</p>
          </div>
        </div>
        
        <!-- User Orders Section -->
        <div v-if="user.orders && user.orders.length > 0" class="orders-section">
          <h2>User Orders</h2>
          <div class="orders-table-container">
            <table class="orders-table">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Date</th>
                  <th>Total Cost</th>
                  <th>Status</th>
                  <th>Items</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in user.orders" :key="order.order_id">
                  <td>{{ order.order_id }}</td>
                  <td>{{ formatDate(order.created_at) }}</td>
                  <td>${{ parseFloat(order.total_cost).toFixed(2) }}</td>
                  <td><span :class="'status-badge ' + order.status.toLowerCase()">{{ order.status }}</span></td>
                  <td>
                    <button @click="toggleOrderDetails(order.order_id)" class="view-details-btn">
                      {{ expandedOrders.includes(order.order_id) ? 'Hide Details' : 'View Details' }}
                    </button>
                  </td>
                </tr>
                <tr v-for="order in user.orders" :key="'details-' + order.order_id" v-show="expandedOrders.includes(order.order_id)" class="order-details-row">
                  <td colspan="5">
                    <div class="order-items">
                      <div v-for="(item, index) in order.items" :key="index" class="order-item">
                        <div class="item-image" v-if="item.image_link && item.image_link.length > 0">
                          <img :src="item.image_link[0]" alt="Item image">
                        </div>
                        <div class="item-details">
                          <h4>{{ item.dealTitle }}</h4>
                          <p v-if="item.dealIdFormatted" class="deal-id">Deal ID: {{ item.dealIdFormatted }}</p>
                          <p>Quantity: {{ item.quantity }}</p>
                          <p>Price: ${{ parseFloat(item.totalCost).toFixed(2) }}</p>
                          <!-- Refund button for gift cards that aren't refunded -->
                          <button 
                            v-if="item.giftCardId && !item.refunded" 
                            @click="refundItem(order.order_id, item.giftCardId)"
                            class="refund-button"
                            :disabled="isRefunding === item.giftCardId"
                          >
                            {{ isRefunding === item.giftCardId ? 'Processing...' : 'Refund Gift Card' }}
                          </button>
                          <!-- Refund status display -->
                          <p v-if="item.refunded" class="refund-status">
                            <span class="status-refunded">Refunded</span>
                            <span v-if="item.refundedAt" class="refund-time">on {{ formatDate(item.refundedAt) }}</span>
                          </p>
                          <!-- Conditional rendering for redemption status - only show if not refunded -->
                          <p v-if="!item.refunded && item.redemptionSummary">
                            Status: 
                            <span v-if="item.redemptionSummary.partiallyRedeemed" class="status-partial">
                              Partially Redeemed ({{ item.redemptionSummary.redeemed }}/{{ item.redemptionSummary.total }})
                            </span>
                            <span v-else-if="item.redemptionSummary.redeemed === item.redemptionSummary.total" class="status-redeemed">
                              Fully Redeemed
                            </span>
                            <span v-else class="status-not-redeemed">
                              Not Redeemed
                            </span>
                          </p>
                          <!-- Show redemption timestamps when available and not refunded -->
                          <div v-if="!item.refunded && item.redemptionSummary && item.redemptionSummary.redemptionTimestamps && item.redemptionSummary.redemptionTimestamps.length > 0" class="redemption-timestamps">
                            <p><strong>Redemption Times:</strong></p>
                            <ul class="timestamp-list">
                              <li v-for="(timestamp, idx) in item.redemptionSummary.redemptionTimestamps" :key="idx">
                                Certificate #{{ idx + 1 }}: <span class="status-redeemed">Redeemed</span> on {{ formatDate(timestamp) }}
                              </li>
                              <li v-for="idx in getUnredeemedCount(item)" :key="`unredeemed-${idx}`">
                                Certificate #{{ item.redemptionSummary.redemptionTimestamps.length + idx }}: <span class="status-not-redeemed">Not Redeemed</span>
                              </li>
                            </ul>
                          </div>
                          <!-- Only show this if redemptionSummary is not available and not refunded -->
                          <p v-else-if="!item.refunded && item.redeemed">
                            Status: <span class="status-redeemed">Redeemed</span>
                            <span v-if="item.redeemedAt" class="redemption-time">on {{ formatDate(item.redeemedAt) }}</span>
                          </p>
                          <p v-else-if="!item.refunded && !item.redemptionSummary">
                            Status: <span class="status-not-redeemed">Not Redeemed</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else-if="user.orders" class="no-orders">
          <p>No orders found for this user.</p>
        </div>
      </div>
      <div v-if="isLoading" class="loading-overlay">
        <div class="loader"></div>
        <p>Updating the user, please wait...</p>
      </div>
      <div v-if="isUpdated" class="updated-overlay">
        <p>User Updated Successfully</p>
      </div>
      
      <!-- Custom Refund Confirmation Modal -->
      <div v-if="showRefundModal" class="modal-overlay">
        <div class="modal-container">
          <div class="modal-header">
            <h3>Confirm Refund</h3>
          </div>
          <div class="modal-body">
            <p>Are you sure that you want to do this? Once you confirm, this can't be undone.</p>
            <p>You will still need to go into payment gateway to refund the customer, this just removes it from our system.</p>
          </div>
          <div class="modal-footer">
            <button @click="confirmRefund" class="confirm-button">Confirm Refund</button>
            <button @click="cancelRefund" class="cancel-modal-button">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import MetaManager from '../../components/MetaManager.vue';

export default {
  name: 'DashboardUpdateUser',
  components: {
    MetaManager,
  },
  data() {
    return {
      searchTerm: '',
      searchType: 'email', // Default search type
      user: null,
      userSuggestions: [],
      businessName: '',
      businessSuggestions: [],
      isLoading: false, // State to control the loading spinner visibility
      isUpdated: false, // State to control the "User Updated" message visibility
      expandedOrders: [], // Track which order details are expanded
      resetLink: null, // Store the generated password reset link
      isGeneratingLink: false, // Track when link is being generated
      linkCopied: false, // Track when link is copied to clipboard
      isRefunding: null, // Track which gift card is being refunded
      showRefundModal: false, // Control visibility of the refund confirmation modal
      pendingRefundOrder: null, // Store order ID of pending refund
      pendingRefundGiftCard: null, // Store gift card ID of pending refund
    };
  },
  async created() {
    // Check if email is passed as a query parameter
    const emailFromQuery = this.$route.query.email;
    if (emailFromQuery) {
      this.searchTerm = emailFromQuery;
      this.searchType = 'email';
      await this.fetchUserBySearchTerm(emailFromQuery);
    }
  },
  methods: {
    async fetchUserBySearchTerm(term) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/updates/search-user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: { searchTerm: term },
        });

        if (response.data.length > 0) {
          this.user = response.data[0];
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    },
    async searchUser() {
      if (this.searchTerm.length > 2) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/updates/search-user`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            params: {
              searchTerm: this.searchTerm,
            },
          });
          this.userSuggestions = response.data;
        } catch (error) {
          console.error('Error fetching user:', error);
        }
      } else {
        this.userSuggestions = [];
      }
    },
    selectUser(user) {
      this.user = user;
      this.searchTerm = this.searchType === 'email' ? user.email : 
                        this.searchType === 'phone' ? user.phone : user.full_name;
      this.userSuggestions = [];
      this.expandedOrders = []; // Reset expanded orders when selecting a new user
      // Clear password reset link when selecting a new user
      this.resetLink = null;
      this.linkCopied = false;
    },
    async searchBusiness() {
      if (this.businessName.length > 2) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/updates/search-business`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            params: {
              name: this.businessName,
            },
          });
          this.businessSuggestions = response.data;
        } catch (error) {
          console.error('Error fetching business:', error);
        }
      } else {
        this.businessSuggestions = [];
      }
    },
    selectBusiness(business) {
      this.user.business_id = business._id;
      this.businessName = business.business_name;
      this.businessSuggestions = [];
    },
    checkBusinessStatus() {
      if (!this.user.is_business) {
        this.user.business_id = '';
      }
    },
    async updateUser() {
      this.isLoading = true; // Show the loading spinner
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/updates/update-user`, {
          id: this.user.id,
          full_name: this.user.full_name,
          email: this.user.email,
          is_admin: this.user.is_admin,
          is_business: this.user.is_business,
          business_id: this.user.business_id || null,
          is_verified: this.user.is_verified,
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }});

        // Simulate delay for loading spinner visibility
        setTimeout(() => {
          this.isLoading = false; // Hide the spinner
          this.isUpdated = true; // Show the "User Updated" message

          // Hide the "User Updated" message after a few seconds
          setTimeout(() => {
            this.isUpdated = false;
            location.reload();
          }, 3000); // Adjust this value to control how long the message is shown
        }, 2000); // Adjust this delay as needed
      } catch (error) {
        console.error('Error updating user:', error);
        this.isLoading = false; // Ensure spinner is hidden in case of error
      }
    },
    async banUser() {
      this.isLoading = true; // Show the loading spinner
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/updates/ban-user`, {
          id: this.user.id,
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }});

        // Simulate delay for loading spinner visibility
        setTimeout(() => {
          this.isLoading = false; // Hide the spinner
          this.isUpdated = true; // Show the "User Updated" message

          // Hide the "User Updated" message after a few seconds
          setTimeout(() => {
            this.isUpdated = false;
            location.reload();
          }, 3000); // Adjust this value to control how long the message is shown
        }, 2000); // Adjust this delay as needed
      } catch (error) {
        console.error('Error banning user:', error);
        this.isLoading = false; // Ensure spinner is hidden in case of error
      }
    },
    cancelUpdate() {
      this.clearForm();
    },
    clearForm() {
      this.searchTerm = '';
      this.user = null;
      this.userSuggestions = [];
      this.businessName = '';
      this.businessSuggestions = [];
      this.expandedOrders = [];
      // Clear password reset data when form is cleared
      this.resetLink = null;
      this.linkCopied = false;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString();
    },
    toggleOrderDetails(orderId) {
      if (this.expandedOrders.includes(orderId)) {
        this.expandedOrders = this.expandedOrders.filter(id => id !== orderId);
      } else {
        this.expandedOrders.push(orderId);
      }
    },
    getUnredeemedCount(item) {
      if (!item.redemptionSummary) return 0;
      const total = item.redemptionSummary.total || 0;
      const redeemed = item.redemptionSummary.redeemed || 0;
      return total - redeemed;
    },
    async generateResetLink() {
      if (!this.user) return;
      
      this.isGeneratingLink = true;
      this.resetLink = null;
      this.linkCopied = false;
      
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/forgot-password/generate-reset-link`,
          { userId: this.user.id },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        
        this.resetLink = response.data.resetLink;
      } catch (error) {
        console.error('Error generating reset link:', error);
        alert('Failed to generate reset link. Please try again.');
      } finally {
        this.isGeneratingLink = false;
      }
    },
    copyResetLink() {
      const resetLinkInput = this.$refs.resetLinkInput;
      resetLinkInput.select();
      document.execCommand('copy');
      
      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 3000);
    },
    refundItem(orderId, giftCardId) {
      // Instead of immediately calling confirm(), we'll show our custom modal
      this.pendingRefundOrder = orderId;
      this.pendingRefundGiftCard = giftCardId;
      this.showRefundModal = true;
    },
    
    cancelRefund() {
      // Close the modal and clear pending refund data
      this.showRefundModal = false;
      this.pendingRefundOrder = null;
      this.pendingRefundGiftCard = null;
    },
    
    async confirmRefund() {
      // Close the modal
      this.showRefundModal = false;
      
      // Proceed with the refund process
      const orderId = this.pendingRefundOrder;
      const giftCardId = this.pendingRefundGiftCard;
      
      // Clear pending refund data
      this.pendingRefundOrder = null;
      this.pendingRefundGiftCard = null;
      
      this.isRefunding = giftCardId; // Set the refunding state to show loading
      
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/updates/refund-order`,
          { 
            orderId: orderId, 
            giftCardId: giftCardId 
          },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        
        // Update the item in the local state to show as refunded
        if (this.user && this.user.orders) {
          this.user.orders.forEach(order => {
            if (order.order_id === orderId) {
              order.items.forEach(item => {
                if (item.giftCardId === giftCardId) {
                  item.refunded = true;
                  item.refundedAt = new Date().toISOString();
                }
              });
            }
          });
        }
        
        // Show success message
        alert('Gift card refunded successfully!');
      } catch (error) {
        console.error('Error refunding gift card:', error);
        alert('Failed to refund gift card. Please try again.');
      } finally {
        this.isRefunding = null; // Reset refunding state
      }
    },
  },
};
</script>

<style scoped>
.dashboard-content {
  padding: 20px;
  margin-left: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 40px);
  box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 0; /* Remove margin for sidebar */
    padding: 15px; /* Adjust padding for medium screens */
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    padding: 10px; /* Adjust padding for small screens */
    flex-direction: column; /* Stack elements vertically */
  }
  
  .order-item {
    flex-direction: column;
  }
  
  .item-image {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .dashboard-content {
    padding: 5px; /* Adjust padding for extra small screens */
    max-height: 30vh;
  }
  .form-container{
    margin-top: 350px;
  }
}

.form-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  position: relative;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group,
.form-group-inline {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
}

.form-group-inline {
  flex-direction: row;
  align-items: center;
}

.form-group-inline label {
  width: 100px;
  margin-bottom: 0;
}

.form-group-inline .form-value {
  margin-left: 10px;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group label {
  margin-right: 10px;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.checkbox-field {
  margin-left: 10px;
}

.form-value {
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.submit-button,
.cancel-button,
.ban-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 30%;
}

.submit-button {
  background-color: #4C6B30;
  color: white;
}

.submit-button:hover {
  background-color: #6E9F49;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.ban-button {
  background-color: #dc3545;
  color: white;
}

.ban-button:hover {
  background-color: #c82333;
}

ul {
  background-color: #fff;
  border: 1px solid #ddd;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
}

li {
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
}

li:hover {
  background-color: #f2f2f2;
}

.suggestions {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  z-index: 1000;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #4C6B30;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

.updated-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.updated-overlay p {
  font-size: 18px;
  color: #333;
}

p {
  font-size: 18px;
  color: #333;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Styles for order section */
.orders-section {
  margin-top: 40px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.orders-section h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #4C6B30;
}

.orders-table-container {
  overflow-x: auto;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.orders-table th, .orders-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.orders-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.orders-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.orders-table tr:hover {
  background-color: #f5f5f5;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.status-badge.completed {
  background-color: #d4edda;
  color: #155724;
}

.status-badge.pending {
  background-color: #fff3cd;
  color: #856404;
}

.status-badge.cancelled {
  background-color: #f8d7da;
  color: #721c24;
}

.status-badge.processing {
  background-color: #cce5ff;
  color: #004085;
}

.view-details-btn {
  background-color: #4C6B30;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.view-details-btn:hover {
  background-color: #6E9F49;
}

.order-details-row {
  background-color: #f8f9fa !important;
}

.order-items {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.order-item {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: white;
}

.item-image {
  width: 80px;
  height: 80px;
  margin-right: 15px;
  overflow: hidden;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.item-details {
  flex: 1;
}

.item-details h4 {
  margin: 0 0 5px 0;
  color: #333;
}

.item-details p {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.status-redeemed {
  color: #155724;
  background-color: #d4edda;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
}

.status-not-redeemed {
  color: #856404;
  background-color: #fff3cd;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
}

.status-partial {
  color: #0c5460;
  background-color: #d1ecf1;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
}

.redemption-timestamps {
  margin-top: 5px;
}

.timestamp-list {
  margin-top: 5px;
  padding-left: 20px;
  overflow: visible;
  background: transparent;
  border: none;
  max-height: none;
}

.timestamp-list li {
  font-size: 13px;
  color: #555;
  padding: 4px 0;
  cursor: default;
  background-color: #f9f9f9;
  margin-bottom: 4px;
  border-radius: 4px;
  border-left: 3px solid #4C6B30;
  padding-left: 10px;
}

.timestamp-list li:hover {
  background-color: #f0f0f0;
}

.redemption-time {
  font-weight: normal;
  margin-left: 5px;
}

.no-orders {
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.no-orders p {
  color: #6c757d;
  font-style: italic;
}

.search-type-toggle {
  display: flex;
  margin-bottom: 10px;
  gap: 15px;
  justify-content: center;
}

.search-type-toggle label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search-type-toggle input {
  margin-right: 5px;
}

/* Add styling for the deal ID */
.deal-id {
  font-size: 12px;
  color: #666;
  background-color: #f0f0f0;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 5px;
}

/* Styles for password reset section */
.password-reset-section {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.reset-password-button {
  background-color: #17a2b8;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  margin-bottom: 15px;
}

.reset-password-button:hover {
  background-color: #138496;
}

.reset-password-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.reset-link-container {
  margin-top: 10px;
}

.reset-link-display {
  display: flex;
  margin-top: 5px;
}

.reset-link-input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  font-size: 14px;
  background-color: #f0f0f0;
  color: #333;
}

.copy-button {
  background-color: #4C6B30;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #6E9F49;
}

.copy-confirmation {
  color: #4C6B30;
  font-size: 14px;
  margin-top: 5px;
  font-weight: bold;
}

/* Ensure this comes after any existing styles */
/* Refund status styles */
.refund-status {
  margin-top: 5px;
  font-weight: bold;
}

.status-refunded {
  color: #721c24;
  background-color: #f8d7da;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
}

.refund-time {
  font-weight: normal;
  margin-left: 5px;
}

/* Refund button styles */
.refund-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: background-color 0.3s;
}

.refund-button:hover {
  background-color: #c82333;
}

.refund-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.modal-container {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.modal-header {
  background-color: #f8d7da;
  padding: 15px 20px;
  border-bottom: 1px solid #f5c6cb;
}

.modal-header h3 {
  margin: 0;
  color: #721c24;
  font-size: 18px;
}

.modal-body {
  padding: 20px;
}

.modal-body p {
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.modal-footer {
  padding: 15px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.confirm-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.confirm-button:hover {
  background-color: #c82333;
}

.cancel-modal-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.cancel-modal-button:hover {
  background-color: #5a6268;
}
</style>